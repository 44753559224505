import { useEffect, useState } from "react"
import Marquee from "react-fast-marquee"

import { MARQ_SETTINGS, timezones } from "../../utils/constants"
import { getTime } from '../../utils/helpers'
import "./style.css"

export default function Timezones() {
  const [timestate, setTimestate] = useState([])

  useEffect(() => {
    setInterval(() => setTimes(), 1000)
  }, [])

  const setTimes = () => setTimestate(timezones.map(timezone => getTime(timezone[1])))

  return (
    <div className="sidescroller timezones">
      <Marquee {...MARQ_SETTINGS}>
        {timezones.map((tz, i) => (
          <div className="scrollContainer">
            <p className="scrollText">
              {tz[0]}
            </p>
            <p className="altText time">{timestate[i]}</p>
          </div>
        ))}
      </Marquee>
    </div>
  )
}