import React, { useEffect, useRef, useState } from "react"
import Marquee from "react-fast-marquee"
import Tilt from 'react-tilt'

import {
  NavLinks,
  AuctionStart,
  AllowListStart,
  PublicSaleStart,
  FaqLine
} from "./components/MiniComponents"
import MusicWidget from "./components/MusicWidget"
import MintForm from "./components/MintForm/index.jsx"
import Socials from "./components/Socials"
import NFTSection from "./components/NFTSection"
import ExploreSection from "./components/ExploreSection"
import MintSection, { MintButton } from "./components/MintSection/index.jsx"
import TeamSection from "./components/TeamSection"
import FaqSection from "./components/FaqSection"
import Footer from "./components/Footer"
import Timezones from "./components/Timezones"

import { MARQ_SETTINGS } from "./utils/constants"


import rep1 from './assets/dedfamz/reps/94-min.png'
import rep1Json from './assets/dedfamz/reps/94.json'
import rep2 from './assets/dedfamz/reps/700-min.png'
import rep2Json from './assets/dedfamz/reps/700.json'
import rep3 from './assets/dedfamz/reps/870-min.png'
import rep3Json from './assets/dedfamz/reps/870.json'
import bg from './assets/backgrounds/Sky.png'
import logo from './assets/branding/DedfamLogo.png'
import dedfamSpin from './assets/branding/SkullSpin.png'

import "./styles/style.css"
import Card from "./components/Card"

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input

function App() {
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`)
  const [mintAmount, setMintAmount] = useState(1)
  const [mintModalOpen, setMintModalOpen] = useState(false)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  })

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST
    let gasLimit = CONFIG.GAS_LIMIT
    let totalCostWei = String(cost * mintAmount)
    let totalGasLimit = String(gasLimit * mintAmount)
    console.log("Cost: ", totalCostWei)
    console.log("Gas limit: ", totalGasLimit)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err)
        setFeedback("Sorry, something went wrong please try again later.")
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1
    if (newMintAmount < 1) newMintAmount = 1
    setMintAmount(newMintAmount)
  }

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1
    if (newMintAmount > 10) newMintAmount = 10
    setMintAmount(newMintAmount)
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  const [isHide, setIsHide] = useState(true)
  const mintRef = useRef()

  const handleShowMintButton = () => {
    const mintRefPos = mintRef.current.offsetTop
    setIsHide(window.scrollY < mintRefPos)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleShowMintButton)
    return () => {
      window.removeEventListener("scroll", handleShowMintButton)
    }
  }, [handleShowMintButton])

  return (
    <div className="app">
      <div className={`fixedMintButton ${isHide ? 'hidden' : ''}`}>
        <MintButton setMintModalOpen={setMintModalOpen} mintModalOpen={mintModalOpen} />
      </div>

      <nav className="nav">
        <Marquee {...MARQ_SETTINGS} speed={40}>
          <NavLinks setMintModalOpen={setMintModalOpen} />
          <NavLinks setMintModalOpen={setMintModalOpen} />
          <NavLinks setMintModalOpen={setMintModalOpen} />
          <NavLinks setMintModalOpen={setMintModalOpen} />
          <NavLinks setMintModalOpen={setMintModalOpen} />
        </Marquee>
      </nav>

      <MintForm setMintModalOpen={setMintModalOpen} mintModalOpen={mintModalOpen} />

      <div className="splashContainer">
        <div className="splashHorizontal">
          <div className="splash">
            <div className="emblemContainer" onClick={() => setMintModalOpen(true)}>
              <img src={dedfamSpin} className="skull" />
              <img src={bg} className="skullCircle" />
              <img src={logo} className="dedfamLogo" />
            </div>

            <div className="cover" />
          </div>

          <div className="splashScrollContainer sidescroller">
            <Marquee {...MARQ_SETTINGS} speed={70} className="marqLarge">
              <h3>DEDFAM</h3>
              <h3>DEDFAM</h3>
              <h3>DEDFAM</h3>
              <h3>DEDFAM</h3>
              <h3>DEDFAM</h3>
              <h3>DEDFAM</h3>
            </Marquee>
          </div>

          <div className="splashScrollWorldContainer sidescroller">
            <Marquee {...MARQ_SETTINGS} direction="right">
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
              <p>.World</p>
            </Marquee>
          </div>
        </div>

        <div className="sidescroller timezones textScroller">
          <Marquee {...MARQ_SETTINGS}>
            <div className="scrollContainer">
              <p className="scrollText">Street Style Collective</p>
            </div>
            <div className="scrollContainer">
              <p className="altText">Street Style Collective</p>
            </div>
            <div className="scrollContainer">
              <p className="scrollText">Street Style Collective</p>
            </div>
            <div className="scrollContainer">
              <p className="altText">Street Style Collective</p>
            </div>
            <div className="scrollContainer">
              <p className="scrollText">Street Style Collective</p>
            </div>
            <div className="scrollContainer">
              <p className="altText">Street Style Collective</p>
            </div>
          </Marquee>
        </div>
      </div>

      <MintSection setMintModalOpen={setMintModalOpen} mintModalOpen={mintModalOpen} />

      <div className="sidescroller">
        <Marquee {...MARQ_SETTINGS}>
          {AuctionStart}
          {AuctionStart}
          {AuctionStart}
          {AuctionStart}
          {AuctionStart}
        </Marquee>
      </div>

      <div className="intro" ref={mintRef}>
        <p className="introBlurb">Dedfam is a thoughtfully crafted street style NFT collection.</p>

        <div className="portraitContainer">
          <Card pic={rep2} json={rep2Json} className="portraitCard" />
          <Card pic={rep1} json={rep1Json} className="portraitCard" />
          <Card pic={rep3} json={rep3Json} className="portraitCard" />
        </div>
      </div>

      <div className="communityContainer sidescroller">
        <Marquee {...MARQ_SETTINGS} speed={70} className="marqLarge">
          <h3>COME HANG.</h3>
          <h3>JOIN DEDFAM.</h3>
          <h3>COME HANG.</h3>
          <h3>JOIN DEDFAM.</h3>
          <h3>COME HANG.</h3>
          <h3>JOIN DEDFAM.</h3>
        </Marquee>
      </div>

      <Socials />

      <Timezones />

      <NFTSection />

      <div className="sidescroller">
        <Marquee {...MARQ_SETTINGS}>
          <p className="scrollText marginLeft">Dedfam is royalty free •</p>
          <p className="altText marginLeft">Dedfam is CC0 •</p>
          <p className="scrollText marginLeft">Dedfam is royalty free •</p>
          <p className="altText marginLeft">Dedfam is CC0 •</p>
          <p className="scrollText marginLeft">Dedfam is royalty free •</p>
          <p className="altText marginLeft">Dedfam is CC0 •</p>
          <p className="scrollText marginLeft">Dedfam is royalty free •</p>
          <p className="altText marginLeft">Dedfam is CC0 •</p>
        </Marquee>
      </div>

      <ExploreSection />

      <div className="sidescroller">
        <Marquee {...MARQ_SETTINGS}>
          <p className="scrollText marginLeft">NO CORPORATE PARTNERS •</p>
          <p className="altText marginLeft">No venture capital •</p>
          <p className="scrollText marginLeft">NO CORPORATE PARTNERS •</p>
          <p className="altText marginLeft">No venture capital •</p>
          <p className="scrollText marginLeft">NO CORPORATE PARTNERS •</p>
          <p className="altText marginLeft">No venture capital •</p>
        </Marquee>
      </div>

      <TeamSection />

      <div className="sidescroller">
        <Marquee {...MARQ_SETTINGS}>
          <p className="scrollText marginLeft">JOIN DEDFAM •</p>
          <p className="altText marginLeft">Come hang •</p>
          <p className="scrollText marginLeft">JOIN DEDFAM •</p>
          <p className="altText marginLeft">Come hang •</p>
          <p className="scrollText marginLeft">JOIN DEDFAM •</p>
          <p className="altText marginLeft">Come hang •</p>
        </Marquee>
      </div>

      <FaqSection />

      <div className="sidescroller">
        <Marquee {...MARQ_SETTINGS}>
          <p className="scrollText marginLeft">FRESH TO DEATH •</p>
          <p className="altText marginLeft">Mad style •</p>
          <p className="scrollText marginLeft">FRESH TO DEATH •</p>
          <p className="altText marginLeft">Mad style •</p>
          <p className="scrollText marginLeft">FRESH TO DEATH •</p>
          <p className="altText marginLeft">Mad style •</p>
          <p className="scrollText marginLeft">FRESH TO DEATH •</p>
          <p className="altText marginLeft">Mad style •</p>
          <p className="scrollText marginLeft">FRESH TO DEATH •</p>
          <p className="altText marginLeft">Mad style •</p>
        </Marquee>
      </div>

      <Socials />

      <Footer />
    </div >
  )
}

export default App

{/* <s.Screen>
<s.Container
  flex={1}
  ai={"center"}
  style={{ padding: 24, backgroundColor: "var(--primary)" }}
  image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
>
  <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
  <s.SpacerSmall />
  <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
    <s.Container flex={1} jc={"center"} ai={"center"}>
      <StyledImg alt={"example"} src={"/config/images/example.gif"} />
    </s.Container>
    <s.SpacerLarge />
    <s.Container
      flex={2}
      jc={"center"}
      ai={"center"}
      style={{
        backgroundColor: "var(--accent)",
        padding: 24,
        borderRadius: 24,
        border: "4px dashed var(--secondary)",
        boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
      }}
    >
      <s.TextTitle
        style={{
          textAlign: "center",
          fontSize: 50,
          fontWeight: "bold",
          color: "var(--accent-text)",
        }}
      >
        {data.totalSupply} / {CONFIG.MAX_SUPPLY}
      </s.TextTitle>
      <s.TextDescription
        style={{
          textAlign: "center",
          color: "var(--primary-text)",
        }}
      >
        <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
          {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
        </StyledLink>
      </s.TextDescription>
      <s.SpacerSmall />
      {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
        <>
          <s.TextTitle
            style={{ textAlign: "center", color: "var(--accent-text)" }}
          >
            The sale has ended.
          </s.TextTitle>
          <s.TextDescription
            style={{ textAlign: "center", color: "var(--accent-text)" }}
          >
            You can still find {CONFIG.NFT_NAME} on
          </s.TextDescription>
          <s.SpacerSmall />
          <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
            {CONFIG.MARKETPLACE}
          </StyledLink>
        </>
      ) : (
        <>
          <s.TextTitle
            style={{ textAlign: "center", color: "var(--accent-text)" }}
          >
            1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
            {CONFIG.NETWORK.SYMBOL}.
          </s.TextTitle>
          <s.SpacerXSmall />
          <s.TextDescription
            style={{ textAlign: "center", color: "var(--accent-text)" }}
          >
            Excluding gas fees.
          </s.TextDescription>
          <s.SpacerSmall />
          {blockchain.account === "" ||
          blockchain.smartContract === null ? (
            <s.Container ai={"center"} jc={"center"}>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                Connect to the {CONFIG.NETWORK.NAME} network
              </s.TextDescription>
              <s.SpacerSmall />
              <StyledButton
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(connect())
                  getData()
                }}
              >
                CONNECT
              </StyledButton>
              {blockchain.errorMsg !== "" ? (
                <>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    {blockchain.errorMsg}
                  </s.TextDescription>
                </>
              ) : null}
            </s.Container>
          ) : (
            <>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                {feedback}
              </s.TextDescription>
              <s.SpacerMedium />
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                <StyledRoundButton
                  style={{ lineHeight: 0.4 }}
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault()
                    decrementMintAmount()
                  }}
                >
                  -
                </StyledRoundButton>
                <s.SpacerMedium />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {mintAmount}
                </s.TextDescription>
                <s.SpacerMedium />
                <StyledRoundButton
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault()
                    incrementMintAmount()
                  }}
                >
                  +
                </StyledRoundButton>
              </s.Container>
              <s.SpacerSmall />
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                <StyledButton
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault()
                    claimNFTs()
                    getData()
                  }}
                >
                  {claimingNft ? "BUSY" : "BUY"}
                </StyledButton>
              </s.Container>
            </>
          )}
        </>
      )}
      <s.SpacerMedium />
    </s.Container>
    <s.SpacerLarge />
    <s.Container flex={1} jc={"center"} ai={"center"}>
      <StyledImg
        alt={"example"}
        src={"/config/images/example.gif"}
        style={{ transform: "scaleX(-1)" }}
      />
    </s.Container>
  </ResponsiveWrapper>
  <s.SpacerMedium />
  <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
    <s.TextDescription
      style={{
        textAlign: "center",
        color: "var(--primary-text)",
      }}
    >
      Please make sure you are connected to the right network (
      {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
      Once you make the purchase, you cannot undo this action.
    </s.TextDescription>
    <s.SpacerSmall />
    <s.TextDescription
      style={{
        textAlign: "center",
        color: "var(--primary-text)",
      }}
    >
      We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
      successfully mint your NFT. We recommend that you don't lower the
      gas limit.
    </s.TextDescription>
  </s.Container>
</s.Container>
</s.Screen> */}
