import { useState } from "react"
import "./style.css"

const FaqLine = ({ title, activeFaq, answer, onClick, index }) => (
  <div className={`faqLine ${activeFaq === index && 'active'}`} onClick={() => onClick(index)}>
    <div className="faqContent">
      <h3>
        {title}
      </h3>
      <button className="plusContainer">
        <div className="plus" />
      </button>
    </div>

    <div className="faqAnswer">
      <p>
        {answer}
      </p>
    </div>
  </div>
)

export default function FaqSection() {
  const [activeFaq, setActiveFaq] = useState()
  const setHandleActiveFaq = (next) => {
    if (next === activeFaq) return setActiveFaq()
    setActiveFaq(next)
  }

  return (
    <div className="faqContainer section sectionSpacing">
      <h3 className="header">FAQ</h3>
      <div className="faq">
        <FaqLine
          title="How do I join Dedfam?"
          index={0}
          activeFaq={activeFaq}
          onClick={setHandleActiveFaq}
          answer="You join Dedfam by owning one of Dedfam's 10,000 unique Ethereum based NFTs."
        />

        <FaqLine
          title="What's your discord like?"
          index={2}
          activeFaq={activeFaq}
          onClick={setHandleActiveFaq}
          answer={
            "Our discord is a place to hang for dedfam holders where we share the latest fashion drops, talk about the latest events in our respective cities, and talk about what's happening in the street art, style, and music scenes."
          }
        />
      </div>
    </div>
  )
}