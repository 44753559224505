import Card from '../Card'

import kenzo from '../../assets/dedfamz/team/22-min.png'
// import kenzoJson from '../../assets/dedfamz/team/22.json'
import Derek from '../../assets/dedfamz/team/93-min.png'
// import DerekJson from '../../assets/dedfamz/team/93.json'
import Matt from '../../assets/dedfamz/team/154-min.png'
// import MattJson from '../../assets/dedfamz/team/154.json'


import "./style.css"

export default function TeamSection() {
  return (
    <div className="team section sectionSpacing">
      <h3 className="header white">TEAM</h3>
      <div className="teammatesContainer">
        <div className="teammates">
          <Card
            pic={kenzo}
            name="kenz0x"
            description="Designer, Engineer, 3D Artist"
            location="Detroit, Tokyo, NYC"
            className="portraitCard"
          />

          <Card
            pic={Derek}
            name="dboi"
            description="3D Artist, Art Director"
            location="LA, SF, NYC"
            className="portraitCard"
          />

          <Card
            pic={Matt}
            name="machu"
            description="3D Artist, Art Director"
            location="Honolulu, SF, NYC"
            className="portraitCard"
          />
        </div>
      </div>
    </div>
  )
}