import aura from '../../assets/backgrounds/Aura.png'
import amoeba from '../../assets/backgrounds/Amoeba.png'
import electric from '../../assets/backgrounds/Electric.png'
import "./style.css"

export default function Socials() {
  return (
    <div className="socialsContainer">
      <a className="social" href="https://twitter.com/dedfamworld" target="_blank">
        <h3>TWITTER</h3>
        <div className="socialImageContainer">
          <div className="socialOverlay" />
          <img className="socialImages" src={electric} />
        </div>
      </a>

      <a className="social" href="https://discord.gg/qVQS8q36xv" target="_blank">
        <h3>DISCORD</h3>
        <div className="socialImageContainer">
          <div className="socialOverlay" />
          <img className="socialImages" src={aura} />
        </div>
      </a>

      <a className="social" href="https://instagram.com/dedfamworld" target="_blank">
        <h3>INSTAGRAM</h3>
        <div className="socialImageContainer">
          <div className="socialOverlay" />
          <img className="socialImages" src={amoeba} />
        </div>
      </a>
    </div>
  )
}