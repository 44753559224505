import Tilt from 'react-tilt'
import background from '../../assets/traits/BackgroundTrait.png'
import bodywear from '../../assets/traits/BodywearTrait.png'
import eyewear from '../../assets/traits/EyewearTrait.png'
import headwear from '../../assets/traits/HeadwearTrait.png'
import mouth from '../../assets/traits/MouthTrait.png'
import skull from '../../assets/traits/SkullTrait.png'
import teeth from '../../assets/traits/TeethTrait.png'
import all from '../../assets/traits/AllTraits.png'
import "./style.css"

export default function NFTSection(props) {
  return (
    <div className="nft">
      <div className="traitTitle">
        <h3>TRAITS</h3>
        <ul>
          <li>
            <p>• 8 total traits</p>
          </li>

          <li>
            <p>• 10,000 unique Dedfam</p>
          </li>

          <li>
            <p>• Ethereum blockchain based</p>
          </li>

          <li>
            <p>• CC0 Royalty Free</p>
          </li>
        </ul>
      </div>

      <div className="traitGrid">
        <div className="traitContainer">
          <div className="traitText">
            <h5>Bodywear</h5>
            <p>Track-Black</p>
          </div>
          <img src={bodywear} className="trait" alt="" />
        </div>

        <div className="traitContainer">
          <div className="traitText">
            <h5>Eyewear</h5>
            <p>Fire-Red</p>
          </div>
          <img src={eyewear} className="trait" alt="" />
        </div>

        <div className="traitContainer">
          <div className="traitText">
            <h5>Headwear</h5>
            <p>Beret-Purple</p>
          </div>
          <img src={headwear} className="trait" alt="" />
        </div>

        <div className="traitContainer">
          <div className="traitText">
            <h5>Mouth</h5>
            <p>Blunt</p>
          </div>
          <img src={mouth} className="trait" alt="" />
        </div>

        <div className="traitContainer">
          <div className="traitText">
            <h5>Skull</h5>
            <p>Goblin</p>
          </div>
          <img src={skull} className="trait" alt="" />
        </div>

        <div className="traitContainer">
          <div className="traitText">
            <h5>Teeth</h5>
            <p>Gold</p>
          </div>
          <img src={teeth} className="trait" alt="" />
        </div>

        <div className="traitContainer">
          <div className="traitText">
            <h5>Background</h5>
            <p>Aura-Rainbow</p>
          </div>
          <img src={background} className="trait" alt="" />
        </div>

        <div className="traitContainer">
          <div className="traitText">
            <h5>City</h5>
            <p>Singapore</p>
          </div>
          <img src={all} className="trait" alt="" />
        </div>
      </div>

      <div className="horizontal">
      </div>
    </div>
  )
}