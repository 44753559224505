import twelve from '../assets/dedfamz/collection/12-min.png'
import twelveJson from '../assets/dedfamz/collection/12.json'
import fifteen from '../assets/dedfamz/collection/15-min.png'
import fifteenJson from '../assets/dedfamz/collection/15.json'
import twofourtwo from '../assets/dedfamz/collection/242-min.png'
import twofourtwoJson from '../assets/dedfamz/collection/242.json'
import threefivefive from '../assets/dedfamz/collection/355-min.png'
import threefivefiveJson from '../assets/dedfamz/collection/355.json'
import foursixseven from '../assets/dedfamz/collection/467-min.png'
import foursixsevenJson from '../assets/dedfamz/collection/467.json'
import fivetwofour from '../assets/dedfamz/collection/524-min.png'
import fivetwofourJson from '../assets/dedfamz/collection/524.json'
import sevensixtwo from '../assets/dedfamz/collection/762-min.png'
import sevensixtwoJson from '../assets/dedfamz/collection/762.json'
import sevenninefive from '../assets/dedfamz/collection/795-min.png'
import sevenninefiveJson from '../assets/dedfamz/collection/795.json'
import eighttwotwo from '../assets/dedfamz/collection/822-min.png'
import eighttwotwoJson from '../assets/dedfamz/collection/822.json'

export const avatars = [
  [twelve, twelveJson],
  [fifteen, fifteenJson],
  [twofourtwo, twofourtwoJson],
  [threefivefive, threefivefiveJson],
  [foursixseven, foursixsevenJson],
  [fivetwofour, fivetwofourJson],
  [sevensixtwo, sevensixtwoJson],
  [sevenninefive, sevenninefiveJson], 
  [eighttwotwo, eighttwotwoJson]
]

export const MARQ_SETTINGS = {
  pauseOnHover: true,
  speed: 30,
  gradient: false,
  className: "marq",
}

export const timezones = [
  ['Honolulu', 'Pacific/Honolulu'],
  ['LA', 'America/Los_Angeles'],
  ['Mexico City', 'America/Mexico_City'],
  ['NYC', 'America/New_York'],
  ['Santiago', 'America/Santiago'],
  ['Sao Paulo', 'America/Sao_Paulo'],
  ['Lisbon', 'Europe/Lisbon'],
  ['London', 'Europe/London'],
  ['Lagos', 'Africa/Lagos'],
  ['Paris', 'Europe/Paris'],
  ['Berlin', 'Europe/Berlin'],
  ['Hong Kong', 'Asia/Hong_Kong'],
  ['Shanghai', 'Asia/Shanghai'],
  ['Seoul', 'Asia/Seoul'],
  ['Tokyo', 'Asia/Tokyo'],
  ['Sydney', 'Australia/Sydney']
]