const checkTime = (i) => {
  if (i < 10) i = "0" + i
  return i
}

export const getTime = (tz) => {
  const timezone = new Date().toLocaleString([], { timeZone: tz })
  const today = new Date(timezone)
  let h = today.getHours()
  let m = today.getMinutes()
  let s = today.getSeconds()
  const isFormat12H = true
  let ampm = ""
  if (isFormat12H) {
    ampm = h >= 12 ? "PM" : "AM"
    h = h % 12
    h = h ? h : 12
  }
  m = checkTime(m)
  s = checkTime(s)
  const time = `${h}:${m}:${s}${ampm}`
  return time
}