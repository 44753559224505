import Tilt from 'react-tilt'
import "./style.css"

export default function Card(props) {
  const { className, pic, description, name: teamName, json } = props
  const {
    name,
    attributes
  } = json || {}

  const city = attributes && attributes[attributes.length - 1].value

  return (
    <div className={`card ${className ? className : ''}`}>
      <Tilt options={{ perspective: 900, scale: 1.05 }}>
        <img src={pic} alt="pic" />
      </Tilt>

      <div className="cardBlurb">
        <h4>
          {teamName || name}
        </h4>

        {description && (
          <p>
            {description}
          </p>
        )}

        {/* {attributes?.map(a => (
          <p>
            {`${a.trait_type}: ${a.value}`}
          </p>
        ))} */}

        {city && <p>{city}</p>}
      </div>
    </div>
  )
}