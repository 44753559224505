import "./style.css"
import logo from '../../assets/branding/DedfamLogo.png'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footerLeft">
        <img src={logo} />
        <p>street style collective</p>
      </div>

      <div className="footerRight">
        <p>
          Made in NYC
        </p>
      </div>
    </footer>
  )
}