import Minter from "../Minter.jsx"
import "./style.css"

export default function MintForm(props) {
  const { mintModalOpen, setMintModalOpen } = props

  return mintModalOpen && (
    <>
      <div className="mintModal">
        <button className="closeContainer" onClick={() => setMintModalOpen(!mintModalOpen)}>
          <div className="close" />
        </button>

        <div className="modalContent">
          <h3>WANNA MINT DEDFAM?</h3>

          {/* <Minter /> */}

          <p>
            Minting starts early 2023 - exact time & date tba.
            <br />
            <br />
            Follow @dedfamworld on Twitter for updates
          </p>
        </div>
      </div>

      <div className="mintModalBG" onClick={() => setMintModalOpen(false)} />
    </>
  )
}