import Tilt from 'react-tilt'
import "./style.css"

export const MintButton = ({ setMintModalOpen, mintModalOpen }) => (
  <Tilt options={{ perspective: 900, scale: 1.15 }}>
    <div className="mintButtonContainer">

      <button className="mintButton" onClick={() => setMintModalOpen(!mintModalOpen)}>
        <p className="">
          MINT
        </p>
      </button>
    </div>
  </Tilt>
)

export default function MintSection({ setMintModalOpen, mintModalOpen }) {
  return (
    <div className="mintContainer">
      <p className="mintInfo">
        0 of 10,000 Minted
      </p>

      <MintButton setMintModalOpen={setMintModalOpen} mintModalOpen={mintModalOpen} />

      <a href="https://opensea.io" target="_blank" className="mintInfo">
        On OpenSea 2023
      </a>
    </div>
  )
}