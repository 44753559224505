import ScrollContainer from 'react-indiana-drag-scroll'
import Card from '../Card'
import { avatars } from '../../utils/constants'
import "./style.css"

export default function ExploreSection() {
  return (
    <div className="exploreSection section sectionSpacingNoWidth">
      <h3 className="white header noPaddingHeader">EXPLORE</h3>
      <ScrollContainer className="explore">
        {avatars.map(c => <Card pic={c[0]} json={c[1]} />)}
        {avatars.map(c => <Card pic={c[0]} json={c[1]} />)}
      </ScrollContainer>
    </div>
  )
}